import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Icon } from "@washingtonpost/wpds-ui-kit";
import Wp from "@washingtonpost/wpds-assets/asset/wp";
import WpMarkLogo from "@washingtonpost/wpds-assets/asset/wp-mark";

const SubsLogo = ({ isShortLogo, size, classes }) => {
  const icons = {
    16: Wp,
    24: Wp,
    32: Wp
  };
  const iconSizes = {
    16: 100,
    24: 150,
    32: 200
  };
  const iconSize = iconSizes[size];
  if (isShortLogo) {
    const Logo = icons[size];
    return (
      <span className={classes}>
        <Icon label="wp-logo" size={iconSize}>
          <Logo />
        </Icon>
      </span>
    );
  }
  return (
    <Icon size={150} style={{ width: "130px", height: "25px" }}>
      <WpMarkLogo label="wp-logo" />
    </Icon>
  );
};

SubsLogo.propTypes = {
  size: PropTypes.string,
  classes: PropTypes.string,
  isShortLogo: PropTypes.bool
};

const SubsLabel = ({ subsPrimaryLabel, subsSecondaryLabel, isShortLogo }) => (
  <Fragment>
    <div className="relative pt-sm">
      <div
        className={`absolute top-0 w-100 z-2 center ${
          subsPrimaryLabel || !isShortLogo ? "mt-xxs" : ""
        }`}
      >
        {subsPrimaryLabel ? (
          <Fragment>
            <span
              className="b bc-secondary bg-white inline-flex items-center justify-center mt-xxs pl-xs pr-xs"
              style={{ letterSpacing: "0.25px" }}
            >
              <SubsLogo size="16" isShortLogo={true} classes="inline-flex" />
              <span
                className="font-xxxxs bold pl-xs"
                style={{ paddingTop: "2px", paddingBottom: "2px" }}
              >
                {subsPrimaryLabel.toUpperCase()}
              </span>
              {subsSecondaryLabel && (
                <span
                  className="font-xxxxs b bc-secondary bl pl-xs ml-xs"
                  style={{ paddingTop: "2px", paddingBottom: "2px" }}
                >
                  {subsSecondaryLabel.toUpperCase()}
                </span>
              )}
            </span>
          </Fragment>
        ) : (
          <span data-qa={`inline-subs-${isShortLogo ? "short" : "long"}-logo`}>
            <SubsLogo
              size="32"
              classes="bg-white pl-xs pr-xs"
              isShortLogo={isShortLogo}
            />
          </span>
        )}
      </div>
    </div>
  </Fragment>
);

SubsLabel.propTypes = {
  subsPrimaryLabel: PropTypes.string,
  subsSecondaryLabel: PropTypes.string,
  isShortLogo: PropTypes.bool
};

export default SubsLabel;
