import React from "react";
import PropTypes from "prop-types";

export const fallbackAltText = "Image without a caption";

/**
 * @class Image
 * @param {Object} props - image properties
 */
const Image = (props) => {
  const {
    caption = "",
    captionClassName = "",
    className = "",
    credits = {},
    creditsClassName = "",
    imgClassName = "",
    placeholderImage = "",
    separator = "|",
    showCredits = true,
    url,
    ImgTag = "img",
    layout,
    height,
    width,
    alt
  } = props;
  let src = url || placeholderImage;
  const byCredits =
    (credits.by &&
      credits.by.map((credit) => credit.byline).join(` ${separator} `)) ||
    "";
  const affiliationCredits =
    (credits.affiliation &&
      credits.affiliation
        .map((credit) => credit.name)
        .join(` ${separator} `)) ||
    "";
  const creditString =
    showCredits &&
    `${byCredits}${
      byCredits && affiliationCredits && ` ${separator} `
    }${affiliationCredits}`;
  const includeCaption = caption || creditString;

  // Make sure all www.washingtonpost.com img srcs are https
  if (src.indexOf("http://www.washingtonpost.com") === 0) {
    src = src.replace("http", "https");
  }

  const imgProps = {
    src,
    alt: alt || caption || fallbackAltText,
    [ImgTag === "amp-img" ? "class" : "className"]: imgClassName,
    height,
    width
  };
  const ampProps = { ...imgProps, layout };
  return (
    <figure className={`${className} hide-for-print`}>
      {ImgTag === "amp-img" ? (
        <ImgTag {...ampProps} />
      ) : (
        <ImgTag {...imgProps} />
      )}
      {includeCaption && (
        <figcaption className={captionClassName}>
          {caption}
          {creditString && (
            <span className={creditsClassName}>{creditString}</span>
          )}
        </figcaption>
      )}
    </figure>
  );
};

Image.propTypes = {
  sizePreset: PropTypes.string,
  caption: PropTypes.string,
  captionClassName: PropTypes.string,
  className: PropTypes.string,
  credits: PropTypes.shape({
    affiliation: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired
      })
    ),
    by: PropTypes.arrayOf(
      PropTypes.shape({
        byline: PropTypes.string.isRequired
      })
    )
  }),
  creditsClassName: PropTypes.string,
  imgClassName: PropTypes.string,
  placeholderImage: PropTypes.string,
  showCredits: PropTypes.bool,
  separator: PropTypes.string,
  url: PropTypes.string.isRequired,
  ImgTag: PropTypes.oneOf(["img", "amp-img"]),
  layout: PropTypes.string.isRequired,
  // height and width are required because of amp.
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  alt: PropTypes.string
};

export default Image;
